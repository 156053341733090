import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { ConditionalWrapper } from '../ConditionalWrapper'
import { Graphic, graphicVariants } from '../Decoration/components'
import { Link } from '../Link'
import styles from './Title.style'

const TitleStyled = styled.div(props => ({ ...styles(props) }))

function Title({ title, titleTagComponent: TitleTag, size, decoration, className, titleHref, isItalic, ...rest }) {
    const titleHtmlProps = useInnerHtml(title)

    const isExternal = titleHref?.indexOf('http') !== -1 && titleHref?.indexOf('zena.net.hr') === -1
    return (
        <ConditionalWrapper
            condition={!!titleHref}
            Wrapper={Link}
            href={titleHref}
            title={titleHref}
            isExternal={isExternal}>
            <TitleStyled
                size={size}
                decoration={decoration}
                className={className}
                titleHref={titleHref}
                isItalic={isItalic}
                {...rest}>
                <Graphic
                    widthXs={38}
                    widthMd={80}
                    heightMd={60}
                    heightXs={32}
                    variant={graphicVariants.GAMMA}
                    className="title_decoration"
                />
                <div className="title_wrapper">
                    {title && <TitleTag className="title" {...titleHtmlProps} />}
                    {title === 'Powered by Femiplant' && <span className="title__addon">S&B-FEMI-HR-02/25-WIDGET</span>}
                </div>
            </TitleStyled>
        </ConditionalWrapper>
    )
}

// @NOTE: LayoutBuilder returns title sizes as string numbers (1,2,3)
const titleSizes = Object.freeze({
    3: 'small',
    2: 'normal',
    1: 'large'
})

const titleDecorations = Object.freeze({
    1: 'alpha',
    2: 'beta'
})

Title.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    size: PropTypes.oneOf([...Object.values(titleSizes)]),
    decoration: PropTypes.oneOf([...Object.values(titleDecorations)]),
    titleHref: PropTypes.string,
    isItalic: PropTypes.bool
}

Title.defaultProps = {
    className: undefined,
    title: undefined,
    titleTagComponent: 'h1',
    size: titleSizes.NORMAL,
    decoration: titleDecorations.ALPHA,
    titleHref: undefined,
    isItalic: false
}

export { titleSizes, titleDecorations }

export default withErrorBoundary(Title, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Title]: ', error, componentStack)
    }
})
