/* eslint-disable quote-props, no-dupe-keys, max-len */

import { toRem, toRems } from '../../helpers'

const style = ({ theme }) => {
    const config = {
        sm: {
            borderRight: 'none',
            swiper: {
                width: '100%',
                margin: `${toRem(70)} 0 ${toRem(10)} ${toRem(10)}`
            },
            item: {
                width: '60%'
            },
            icon: {
                left: 0
            },
            graphic: {
                left: toRem(-20),
                bottom: toRem(-20)
            }
        },
        md: {
            borderRight: `1px solid ${theme.colors.border.default}`,
            swiper: {
                width: '90%',
                margin: `${toRem(70)} auto ${toRem(20)} auto`
            },
            item: {
                width: '33%'
            },
            icon: {
                right: 0
            },
            graphic: {
                right: toRem(-20),
                bottom: toRem(-20)
            }
        }
    }
    // console.log(...config.sm.icon)

    return {
        position: 'relative',
        width: '100%',
        backgroundColor: theme.colors.secondary,
        borderTop: `1px solid ${theme.colors.border.default}`,
        borderBottom: `1px solid ${theme.colors.border.default}`,
        borderLeft: `1px solid ${theme.colors.border.default}`,
        borderRight: config.sm.borderRight,
        [theme.breakpoints.up('md')]: {
            borderRight: config.md.borderRight
        },
        '.listalicaProizvoda': {
            '&_logo': {
                width: toRem(220),
                height: toRem(70),
                marginLeft: toRem(30),
                position: 'absolute',
                top: toRem(-35),
                background: theme.colors.background.body.default,
                paddingBottom: 0,
                paddingRight: 10,
                paddingLeft: 10
            },
            '&_title': {
                marginLeft: toRem(30),
                paddingRight: 10,
                paddingLeft: 10,
                position: 'absolute',
                top: toRem(-10),
                background: theme.colors.background.body.default,
                fontFamily: theme.typography.fontFamilyAlt,
                fontStyle: theme.typography.font.style.italic,
                fontWeight: theme.typography.fontWeight.medium,
                fontSize: toRem(24),
                color: theme.colors.text.default,
                zIndex: theme.zIndex.over,
                [theme.breakpoints.up('md')]: {
                    marginLeft: toRem(55),
                    top: toRem(-40),
                    fontSize: toRem(46)
                }
            },
            '&_swiper': {
                width: config.sm.swiper.width,
                margin: config.sm.swiper.margin,
                [theme.breakpoints.up('md')]: {
                    width: config.md.swiper.width,
                    margin: config.md.swiper.margin
                }
            },
            '&_item': {
                width: config.sm.item.width,
                height: 'unset',
                [theme.breakpoints.up('md')]: {
                    padding: toRem(30),
                    // width: config.md.item.width
                    maxWidth: config.md.item.width
                }
            },
            '&_slider': {
                width: '105%',
                height: '0',
                display: 'flex',
                justifyContent: 'space-between',
                position: 'absolute',
                top: '30%',
                left: '-2.5%'
            },
            '&_icon': {
                position: 'absolute',
                bottom: 0,
                [theme.breakpoints.down('md')]: {
                    left: config.sm.icon.left
                },
                [theme.breakpoints.up('md')]: {
                    right: config.md.icon.right
                }
            },
            '&_graphic': {
                position: 'absolute',
                zIndex: theme.zIndex.under,
                bottom: toRem(-20),
                [theme.breakpoints.down('md')]: {
                    left: config.sm.graphic.left
                },
                [theme.breakpoints.up('md')]: {
                    right: config.md.graphic.right
                }
            },
            '&_disclaimer': {
                fontSize: toRem(16),
                marginTop: toRem(40),
                [theme.breakpoints.down('md')]: {
                    margin: toRems([0, 20, 35])
                }
            },
            '&_button': {
                zIndex: 10,
                svg: {
                    path: {
                        fill: theme.colors.text.black
                    }
                }
            }
        },
        '.swiper-button-disabled': {
            opacity: 0,
            pointerEvents: 'none'
        },
        '.swiper-pagination-bullet': {
            background: theme.colors.text.default,
            opacity: 1
        },
        '.swiper-pagination-bullet-active': {
            background: theme.colors.text.accent
        }
    }
}

export default style
